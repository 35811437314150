import React, { useState } from 'react';
import { SearchFilters } from '../components/SearchFilters';
import { WineCard } from '../components/WineCard';
import { wineData } from '../data/wineData';

const ITEMS_PER_PAGE = 10;

export const WineDirectory = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedType, setSelectedType] = useState('all');
  const [selectedCountry, setSelectedCountry] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [error, setError] = useState(null);

  const wineTypes = ['Red', 'White', 'Orange', 'Rosé', 'Sparkling'];
  const countries = ['Georgia', 'Moldova', 'Romania'];

  if (!wineData || wineData.length === 0) {
    return (
      <div className="text-center py-8">
        <h2 className="text-xl text-gray-600">No wines available</h2>
      </div>
    );
  }

  const filteredWines = wineData.filter(wine => {
    const matchesSearch = wine.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                         wine.winery.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesType = selectedType === 'all' || wine.type === selectedType;
    const matchesCountry = selectedCountry === 'all' || wine.country === selectedCountry;
    
    return matchesSearch && matchesType && matchesCountry;
  });

  return (
    <div className="container mx-auto px-4 py-8">
      <SearchFilters
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        selectedType={selectedType}
        setSelectedType={setSelectedType}
        selectedCountry={selectedCountry}
        setSelectedCountry={setSelectedCountry}
        wineTypes={wineTypes}
        countries={countries}
      />

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-8">
        {filteredWines.map(wine => (
          <WineCard key={wine.id} wine={wine} />
        ))}
      </div>
    </div>
  );
};

export default WineDirectory;
