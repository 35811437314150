import React, { useState } from 'react';
import { MapPin, Globe } from 'lucide-react';

export const WineCard = ({ wine }) => {
  const [isLoading, setIsLoading] = useState(true);

  if (!wine) {
    return null;
  }

  const createMailtoLink = () => {
    if (!wine?.contact?.email) {
      console.error('Email is missing for wine:', wine);
      return '#';
    }

    const domain = import.meta.env.VITE_DOMAIN_URL || 'https://qvevri.online';
    const to = wine.contact.email;
    const subject = encodeURIComponent(`${wine.name} from ${wine.winery} - Inquiry`);
    
    const emailParts = [
      `Dear ${wine.contact.name || 'Winemaker'},`,
      '',
      `I recently discovered your ${wine.name} on ${domain} and am very interested in learning more about ordering this wine.`,
      '',
      'I would appreciate information about:',
      '• Current availability and minimum order quantities',
      '• Shipping options and delivery timeframes',
      '• Payment methods and terms',
      '• Any current promotions or volume discounts',
      '',
      'Looking forward to your response.',
      '',
      'Best regards,',
      ''
    ];

    const body = encodeURIComponent(emailParts.join('\n'));

    return `mailto:${to}?subject=${subject}&body=${body}`;
  };

  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden">
      <img
        src={wine.image || '/images/wines.jpg'}
        alt={wine.name}
        className={`w-full h-48 object-cover ${isLoading ? 'hidden' : 'block'}`}
        onLoad={() => setIsLoading(false)}
      />
      {isLoading && <div className="h-48 bg-gray-200 animate-pulse"></div>}
      <div className="p-6">
        <div className="flex items-start justify-between">
          <div>
            <h2 className="text-xl font-bold mb-2">{wine.name}</h2>
            <p className="text-gray-600 mb-2">{wine.winery}</p>
          </div>
          <span className="bg-purple-100 text-purple-800 px-2 py-1 rounded-full text-sm">
            {wine.type}
          </span>
        </div>
        
        <div className="flex items-center text-gray-600 mb-4">
          <MapPin size={16} className="mr-1" />
          <span>{wine.region}, {wine.country}</span>
        </div>

        <div className="mb-4">
          <div className="flex justify-between mb-2">
            <span className="text-gray-600">Vintage</span>
            <span>{wine.vintage}</span>
          </div>
          <div className="flex justify-between mb-2">
            <span className="text-gray-600">Grape</span>
            <span>{wine.grape}</span>
          </div>
          <div className="flex justify-between mb-2">
            <span className="text-gray-600">ABV</span>
            <span>{wine.abv}</span>
          </div>
        </div>

        <div className="mb-4">
          <h3 className="font-semibold mb-2">Tasting Notes</h3>
          <p className="text-gray-600 text-sm">{wine.tastingNotes}</p>
        </div>

        <div className="mb-4">
          <h3 className="font-semibold mb-2">Price & Minimum Order</h3>
          <p className="text-lg font-bold text-purple-800">${wine.price} per bottle</p>
          <p className="text-sm text-gray-600">Minimum order: {wine.minOrder} bottles</p>
        </div>

        <div className="border-t pt-4">
          <h3 className="font-semibold mb-2">Winery Information</h3>
          {wine.contact.website && (
            <div className="flex items-center">
              <Globe size={16} className="mr-2 text-gray-400" />
              <a href={`https://${wine.contact.website}`} 
                 target="_blank" 
                 rel="noopener noreferrer" 
                 className="text-purple-600 hover:text-purple-800">
                {wine.contact.website}
              </a>
            </div>
          )}
        </div>

        <a href={createMailtoLink()}
           className="block w-full mt-4 bg-purple-600 text-white py-2 px-4 rounded-lg hover:bg-purple-700 transition-colors text-center">
          Contact for Pre-Sale 🍷
        </a>
      </div>
    </div>
  );
};
