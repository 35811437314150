import React, { useState, useEffect } from 'react';
import { API_ROUTES, fetchWithTimeout } from '../utils/api';
import { toast } from 'react-hot-toast';

const validateEmail = (email) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

const sanitizeInput = (input) => {
  if (typeof input === 'string') {
    return input.trim().replace(/[<>]/g, '');
  }
  if (typeof input === 'number') {
    return input;
  }
  return input;
};

const validateWineData = (data) => {
  const errors = {};
  if (data.price <= 0) errors.price = 'Price must be greater than 0';
  if (data.minOrder <= 0) errors.minOrder = 'Minimum order must be greater than 0';
  if (!/^\d{4}$/.test(data.vintage)) errors.vintage = 'Invalid vintage year';
  if (!data.contact.email || !validateEmail(data.contact.email)) {
    errors.contactEmail = 'Invalid email format';
  }
  return errors;
};

export const WineSubmissionForm = () => {
  const initialFormData = {
    name: '',
    winery: '',
    country: '',
    region: '',
    type: '',
    vintage: '',
    grape: '',
    price: '',
    minOrder: '',
    abv: '',
    tastingNotes: '',
    foodPairings: '',
    contact: {
      name: '',
      email: '', 
      phone: '',
      website: ''
    }
  };

  const [formData, setFormData] = useState(initialFormData);
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
      e.preventDefault();
      setIsSubmitting(true);
      setError('');

      try {
        // Validate form data
        const validationErrors = validateWineData(formData);
        if (Object.keys(validationErrors).length > 0) {
          setError('Please correct the form errors');
          setValidationErrors(validationErrors);
          return;
        }

        const sanitizedData = {
          ...formData,
          name: sanitizeInput(formData.name),
          winery: sanitizeInput(formData.winery),
          // Sanitize other string fields...
          price: parseFloat(formData.price),
          minOrder: parseInt(formData.minOrder),
          id: Date.now()
        };

        const response = await fetchWithTimeout(API_ROUTES.wines, {
          method: 'POST',
          body: JSON.stringify(sanitizedData)
        });

        toast.success('Wine submitted successfully!');
        setSubmitted(true);
        setFormData(initialFormData);

      } catch (error) {
          console.error('Error submitting wine:', error);
          toast.error(error.message || 'Failed to submit wine');
          setError(error.message || 'Failed to submit wine');
      } finally {
          setIsSubmitting(false);
      }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.startsWith('contact.')) {
      const contactField = name.split('.')[1];
      setFormData(prev => ({
        ...prev,
        contact: {
          ...prev.contact,
          [contactField]: value
        }
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  return (
    <div className="max-w-2xl mx-auto p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-6">Submit Your Wine 🍷</h2>
      
      {error && (
        <div className="text-red-600 p-4 bg-red-50 rounded-lg mb-4">
          {error}
        </div>
      )}

      {submitted ? (
        <div className="text-green-600 p-4 bg-green-50 rounded-lg">
          Thank you for your submission! We'll review it and get back to you soon. 🎉
        </div>
      ) : (
        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Basic Wine Information */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Wine Name</label>
              <input
                type="text"
                name="name"
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                value={formData.name}
                onChange={handleChange}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Winery</label>
              <input
                type="text"
                name="winery"
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                value={formData.winery}
                onChange={handleChange}
              />
            </div>
          </div>

          {/* Wine Details */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Country</label>
              <input
                type="text"
                name="country"
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                value={formData.country}
                onChange={handleChange}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Region</label>
              <input
                type="text"
                name="region"
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                value={formData.region}
                onChange={handleChange}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Type</label>
              <select
                name="type"
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                value={formData.type}
                onChange={handleChange}
              >
                <option value="">Select Type</option>
                <option value="Red">Red</option>
                <option value="White">White</option>
                <option value="Orange">Orange</option>
                <option value="Rosé">Rosé</option>
                <option value="Sparkling">Sparkling</option>
              </select>
            </div>
          </div>

          {/* Additional Wine Details */}
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Vintage</label>
              <input
                type="text"
                name="vintage"
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                value={formData.vintage}
                onChange={handleChange}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Grape Variety</label>
              <input
                type="text"
                name="grape"
                required
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                value={formData.grape}
                onChange={handleChange}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Price (USD)</label>
              <input
                type="number"
                name="price"
                required
                step="0.01"
                min="0"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                value={formData.price}
                onChange={handleChange}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Minimum Order</label>
              <input
                type="number"
                name="minOrder"
                required
                min="1"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                value={formData.minOrder}
                onChange={handleChange}
              />
            </div>
          </div>

          {/* Descriptions */}
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">Tasting Notes</label>
              <textarea
                name="tastingNotes"
                required
                rows="3"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                value={formData.tastingNotes}
                onChange={handleChange}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Food Pairings</label>
              <textarea
                name="foodPairings"
                required
                rows="3"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                value={formData.foodPairings}
                onChange={handleChange}
              />
            </div>
          </div>

          {/* Contact Information */}
          <div className="border-t pt-4">
            <h3 className="text-lg font-medium mb-4">Contact Information</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">Contact Name</label>
                <input
                  type="text"
                  name="contact.name"
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                  value={formData.contact.name}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Email</label>
                <input
                  type="email"
                  name="contact.email"
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                  value={formData.contact.email}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Phone</label>
                <input
                  type="tel"
                  name="contact.phone"
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                  value={formData.contact.phone}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">Website</label>
                <input
                  type="url"
                  name="contact.website"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                  value={formData.contact.website}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          <button
            type="submit"
            disabled={isSubmitting}
            className="w-full bg-purple-600 text-white py-2 px-4 rounded-lg hover:bg-purple-700 transition-colors disabled:opacity-50"
          >
            {isSubmitting ? 'Submitting...' : 'Submit Wine for Review 🍷'}
          </button>
        </form>
      )}
    </div>
  );
};