// components/ContactForm.jsx
import React, { useState } from 'react';
import { Send } from 'lucide-react';
import { API_ROUTES, fetchWithTimeout } from '../utils/api';

// Updated email validation regex
const validateEmail = (email) => {
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  return emailRegex.test(String(email).toLowerCase());
};

const sanitizeInput = (input) => {
  return typeof input === 'string' 
    ? input.trim().replace(/[<>]/g, '') 
    : input;
};

export const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });
  const [showSuccess, setShowSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    // Clear validation error when user starts typing
    if (validationErrors[name]) {
      setValidationErrors(prev => ({
        ...prev,
        [name]: ''
      }));
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.name.trim()) errors.name = 'Name is required';
    if (!formData.email.trim()) errors.email = 'Email is required';
    if (!validateEmail(formData.email)) errors.email = 'Invalid email format';
    if (!formData.subject.trim()) errors.subject = 'Subject is required';
    if (!formData.message.trim()) errors.message = 'Message is required';
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setValidationErrors({});
    
    // Validate form
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setValidationErrors(errors);
      return;
    }

    setIsSubmitting(true);
    
    try {
      const sanitizedData = {
        ...formData,
        name: sanitizeInput(formData.name),
        email: sanitizeInput(formData.email.toLowerCase()),
        subject: sanitizeInput(formData.subject),
        message: sanitizeInput(formData.message),
        id: `msg_${Math.random().toString(36).substr(2, 9)}`,
        timestamp: new Date().toISOString(),
        status: 'new'
      };

      const response = await fetchWithTimeout(API_ROUTES.messages, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(sanitizedData)
      });

      if (response.success) {
        setShowSuccess(true);
        setFormData({
          name: '',
          email: '',
          subject: '',
          message: ''
        });

        setTimeout(() => {
          setShowSuccess(false);
        }, 5000);
      } else {
        throw new Error(response.message || 'Failed to send message');
      }
    } catch (error) {
      console.error('Error submitting contact form:', error);
      setError(error.message || 'Failed to send message. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="max-w-md mx-auto">
      {showSuccess && (
        <div className="mb-4 p-2 bg-green-500 text-white rounded-lg text-center">
          Message sent successfully! 📬
        </div>
      )}

      {error && (
        <div className="mb-4 p-2 bg-red-500 text-white rounded-lg text-center">
          {error}
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-3">
        <div className="grid grid-cols-2 gap-3">
          <div>
            <input
              type="text"
              name="name"
              placeholder="Your Name"
              required
              className={`w-full px-3 py-2 bg-purple-800 text-white placeholder-purple-300 border ${
                validationErrors.name ? 'border-red-500' : 'border-purple-700'
              } rounded-lg focus:outline-none focus:border-purple-500`}
              value={formData.name}
              onChange={handleChange}
            />
            {validationErrors.name && (
              <p className="text-red-500 text-sm mt-1">{validationErrors.name}</p>
            )}
          </div>
          <div>
            <input
              type="email"
              name="email"
              placeholder="Your Email"
              required
              className={`w-full px-3 py-2 bg-purple-800 text-white placeholder-purple-300 border ${
                validationErrors.email ? 'border-red-500' : 'border-purple-700'
              } rounded-lg focus:outline-none focus:border-purple-500`}
              value={formData.email}
              onChange={handleChange}
            />
            {validationErrors.email && (
              <p className="text-red-500 text-sm mt-1">{validationErrors.email}</p>
            )}
          </div>
        </div>

        <div>
          <input
            type="text"
            name="subject"
            placeholder="Subject"
            required
            className={`w-full px-3 py-2 bg-purple-800 text-white placeholder-purple-300 border ${
              validationErrors.subject ? 'border-red-500' : 'border-purple-700'
            } rounded-lg focus:outline-none focus:border-purple-500`}
            value={formData.subject}
            onChange={handleChange}
          />
          {validationErrors.subject && (
            <p className="text-red-500 text-sm mt-1">{validationErrors.subject}</p>
          )}
        </div>

        <div>
          <textarea
            name="message"
            placeholder="Your Message"
            required
            rows="3"
            className={`w-full px-3 py-2 bg-purple-800 text-white placeholder-purple-300 border ${
              validationErrors.message ? 'border-red-500' : 'border-purple-700'
            } rounded-lg focus:outline-none focus:border-purple-500 resize-none`}
            value={formData.message}
            onChange={handleChange}
          />
          {validationErrors.message && (
            <p className="text-red-500 text-sm mt-1">{validationErrors.message}</p>
          )}
        </div>

        <button
          type="submit"
          disabled={isSubmitting}
          className="w-full flex items-center justify-center gap-2 px-4 py-2 bg-purple-600 text-white rounded-lg hover:bg-purple-500 transition-colors disabled:opacity-50"
        >
          <Send size={16} />
          {isSubmitting ? 'Sending...' : 'Send Message'}
        </button>
      </form>
    </div>
  );
};